import * as XLSX from "xlsx";
import Moment from "moment";
import { translateStatesfalabbela } from "../objects/translateStates";

const generarArchivoCargaSimpliRoute = async (data) => {
  console.log(data);

  async function createArchivoCargaSimpliRoute(data) {
    console.log("Simpliroute", data);
    const newData = JSON.parse(JSON.stringify(data));

    const composSimpliRoute = [
      [
        "Título*   Requerido",
        "Dirección completa*   Requerida",
        "Carga",
        "Hora inicial",
        "Hora final",
        "Tiempo de servicio",
        "Notas",
        "Latitud",
        "Longitud",
        "ID de referencia",
        "Habilidades requeridas",
        "Habilidades opcionales",
        "Persona de contacto",
        "Teléfono de contacto",
        "Hora inicial 2",
        "Hora final 2",
        "Carga 2",
        "Carga 3",
        "Prioridad",
        "SMS",
        "Correo electrónico de contacto",
        "Carga pick",
        "Carga pick 2",
        "Carga pick 3",
        "Fecha programada",
        "Tipo de visita",
        "Repite",
        "estado",
      ],
      ...newData.map((dato) => {
        // Función para limpiar y evitar duplicados o valores undefined
        console.log(dato);
        try {
          const cleanString = (str) => {
            return str
              ? str
                  .trim()
                  .replace(/^(\s|[#.,;])+/g, "")
                  .replace(/(\s|[#.,;])+$/g, "")
              : "";
          };

          const addressComponents = [
            cleanString(dato.toAddress || dato.address),
            cleanString(
              dato.comuna || dato.commune || dato.commune1 || dato.commune2
            ),
            cleanString(dato.country || "Chile"), // Asegura que Chile esté presente si no se proporciona
          ]
            .filter((item, index, self) => item && self.indexOf(item) === index) // Elimina duplicados y valores vacíos
            .join(", ");

          const formattedAddress = addressComponents.endsWith("Chile")
            ? addressComponents
            : `${addressComponents}, Chile`; // Asegura que "Chile" esté al final

          const latitude =
            dato.toAddressLocation?.latitude ||
            dato.toAddressLocation?._lat ||
            0;
          const longitude =
            dato.toAddressLocation?.longitude ||
            dato.toAddressLocation?._long ||
            0;

          return [
            dato.id,
            formattedAddress, // La dirección limpia y bien formada
            dato.productVolume ? dato.productVolume : 0.01,
            "",
            "",
            3,
            "",
            latitude,
            longitude,
            dato.id,
            "",
            "",
            dato.clientNames
              ? dato.clientNames
              : dato.customer_name
              ? dato.customer_name
              : dato.userName,
            dato.userPhone ? dato.userPhone : dato.customer_phone,
            "",
            "",
            "",
            "",
            "",
            "",
            dato.clientEmail ? dato.clientEmail : dato.customer_email,
            "",
            "",
            "",
            dato.routeEstimatedDate
              ? Moment(Date.now(dato.routeEstimatedDate)).format("DD-MM-YYYY")
              : Moment(Date.now(dato.createdAt)).format("DD-MM-YYYY"),
            dato.delivery_type ? dato.delivery_type : "HD",
            dato.driverLicensePlate ? dato.driverLicensePlate : "",
            dato.falabella_status
              ? translateStatesfalabbela[dato.falabella_status]
              : "",
          ];
        } catch (error) {
          console.log("revisar ID: ", dato);
        }
      }),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(composSimpliRoute);
    XLSX.utils.book_append_sheet(libro, hoja, "VisitExcel");

    XLSX.writeFile(
      libro,
      `DataReportSimpli${Moment(Date.now()).format("YYYYMMDDhhmmss")}.xlsx`
    );
  }

  await createArchivoCargaSimpliRoute(data);
};

export default generarArchivoCargaSimpliRoute;
