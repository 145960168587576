import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import useGlobalConfig from "./globalConfig";
import getMatrixDistanceBicci from "../utils/getMatrixDistanceBicci";

function useGetDataDispachstGroupByDriverId(
  driverID,
  type,
  origin,
  destination
) {
  const [dataOrdesBicciByRider, setDataOrdesBicciByRider] = useState(null);
  const [dataOrdesBicciByRiderGroups, setDataOrdesBicciByRiderGroups] =
    useState(null);
  const [isPointGroups, setIsPointGroups] = useState(null);
  const [dataOrdersExcepcions, setDataOrdersExcepcions] = useState(null);
  const [dataOrderDeliverySusses, setDataOrdersDelivery] = useState(null);
  const [documentoRuote, setDocumentRoute] = useState(null);
  const [
    dataOrdesForRouteGoogleServicesRoutes,
    setdataOrdesForRouteGoogleServicesRoutes,
  ] = useState(null);
  const [dataOrdesForRouteIds, setdataOrdesForRouteIds] = useState(null);
  const { retiredTestsArray } = useGlobalConfig();
  useEffect(() => {
    if (driverID && type && origin && destination) {
      let ultimosSeis = type.slice(-6);

      let year = "20" + ultimosSeis.slice(0, 2); // Asume que el año es 2000+
      let month = ultimosSeis.slice(2, 4) - 1; // Los meses en JavaScript van de 0 a 11
      let day = ultimosSeis.slice(4, 6);

      let fecha = new Date(year, month, day);
      function esFechaValida(date) {
        return date instanceof Date && !isNaN(date);
      }

      if (esFechaValida(fecha)) {
        findRiderOrders(driverID, fecha);
        findRiderDocumenttRoute(type);
      } else {
        ////console.log("No es una fecha válida.");c
      }
    }
  }, [driverID && type && origin && destination]);

  const findRiderOrders = async (idRider, fecha) => {
    const driveOrdersCollectionRef = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );

    const q = query(
      driveOrdersCollectionRef,
      where("driverID", "==", idRider),
      where("status", ">", 2),
      where("status", "<=", 8) // desarrollo => where("status", "<=", 8)
    );

    const querySnapshot = await getDocs(q);

    const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    let waypointsCont = [];
    let includeInList = [];
    const dataOrder = {
      data: "",
      get dataEbiex() {
        return this.data;
      },
      set dataEbiex(data) {
        this.data = data;
      },
    };
    ////console.log("dataOrdesBicci", dataOrdesBicci);
    for (let n = 0; n < dataOrdesBicci.length; n++) {
      const wayPoint = {
        stopover: true,
        location: {
          lat: dataOrdesBicci[n].toAddressLocation.latitude,
          lng: dataOrdesBicci[n].toAddressLocation.longitude,
        },
      };
      includeInList.push(dataOrdesBicci[n].id);
      waypointsCont.push(wayPoint);
    }

    try {
      const i = new Date(fecha);
      const e = new Date(fecha);

      let temptypeRquestCall;

      temptypeRquestCall = "updatedAt";

      function formatDateClosed(fecha) {
        var res = new Date(fecha);

        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      function formatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate()); // es - 1 solo desarrollo
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(1);
        res.setMilliseconds(0);
        return res;
      }

      var rangInitial = formatDate(i);
      var rangFinal = formatDateClosed(e);

      dataOrder.data = [];

      await callIgual();

      async function callIgual() {
        try {
          const dataallOrdersfind5 = collection(
            db,
            process.env.REACT_APP_COL_FALABELLA_ORDERS
          );

          const q = query(
            dataallOrdersfind5,
            where(temptypeRquestCall, ">=", rangInitial),
            where(temptypeRquestCall, "<=", rangFinal)
          );
          const querySnapshot5 = await getDocs(q);
          var dataOrdes = querySnapshot5.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          dataOrdes.sort((a, b) =>
            a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
          );
          let dataOrdesfilter = dataOrdes.filter(
            (item) => !retiredTestsArray.includes("F" + item.id.toString())
          );
          ////console.log("dataOrdesF", dataOrdesfilter);
          ////console.log("includeInList", includeInList);

          let includeInListOrder = dataOrdesfilter.filter((item) =>
            includeInList.includes("F" + item.id.toString())
          );
          ////console.log("includeInListOrder", includeInListOrder);
          dataOrder.data = includeInListOrder;
        } catch (error) {
          //////console.log(error);
        }
      }
    } catch (error) {
      ////console.log(error);
    }

    /// USAR BASE BICCI // AGREGAR DATOS DE TABLAS CLIENTES

    try {
      ////console.log("dataOrdesBicci", dataOrdesBicci);
      ////console.log("Client Table Orders ", dataOrder.data);
      var baseBicciData = dataOrdesBicci;

      async function initMerge(newdataMere) {
        const uniqueMap = new Map();

        async function removeDuplicatesById(array) {
          for (const item of array) {
            if (!uniqueMap.has(item.id)) {
              uniqueMap.set(item.id, item);
            }
          }

          return Array.from(uniqueMap.values());
        }

        var DataMerge = await removeDuplicatesById(baseBicciData);
        var requestWaitPonint = [];

        for (let i = 0; i < DataMerge.length; i++) {
          for (let n = 0; n < newdataMere.length; n++) {
            let socf = "F" + newdataMere[n].id;

            if (socf === DataMerge[i].id) {
              DataMerge[i].address = newdataMere[n].address;
              DataMerge[i].agreed_date = newdataMere[n].agreed_date;
              DataMerge[i].commune1 = newdataMere[n].commune1;
              DataMerge[i].commune2 = newdataMere[n].commune2;
              DataMerge[i].createdAt = newdataMere[n].createdAt;
              DataMerge[i].customer_email = newdataMere[n].customer_email;
              DataMerge[i].customer_name = newdataMere[n].customer_name;
              DataMerge[i].customer_phone = newdataMere[n].customer_phone;
              DataMerge[i].date = newdataMere[n].date;
              DataMerge[i].do = newdataMere[n].do;
              DataMerge[i].falabella_id = newdataMere[n].falabella_id;
              DataMerge[i].falabella_status = newdataMere[n].falabella_status;
              DataMerge[i].height = newdataMere[n].height;
              DataMerge[i].length = newdataMere[n].length;
              DataMerge[i].local_provider = newdataMere[n].local_provider;
              DataMerge[i].lpn = newdataMere[n].lpn;
              DataMerge[i].max_delivery_date = newdataMere[n].max_delivery_date;
              DataMerge[i].number_trip = newdataMere[n].number_trip;
              DataMerge[i].price = newdataMere[n].price;
              DataMerge[i].quantity = newdataMere[n].quantity;
              DataMerge[i].quantity_packages = newdataMere[n].quantity_packages;
              DataMerge[i].region = newdataMere[n].region;
              DataMerge[i].schedule_range = newdataMere[n].schedule_range;
              DataMerge[i].shipper_id = newdataMere[n].shipper_id;
              DataMerge[i].size = newdataMere[n].size;
              DataMerge[i].soc = newdataMere[n].soc;
              DataMerge[i].status = newdataMere[n].status;
              DataMerge[i].updatedAt = newdataMere[n].updatedAt;
              DataMerge[i].weight = newdataMere[n].weight;
              DataMerge[i].width = newdataMere[n].width;
            }
          }
        }

        //   console.log(DataMerge);

        let odrdesDelivery = DataMerge.filter(
          (item) => item.falabella_status === "99"
        );

        let SinExcepciones = DataMerge.filter(
          (item) =>
            item.falabella_status === "1058" ||
            item.falabella_status === "1059" ||
            item.falabella_status === "1060" || // eliminar de 1061 al 1046 solo desarrollo
            item.falabella_status === "99"
        );

        ////console.log("Before filter DataMerge", DataMerge);

        let filtrarExcepciones = DataMerge.filter(
          (item) =>
            item.falabella_status === "1031" ||
            item.falabella_status === "1030" ||
            item.falabella_status === "1032" ||
            item.falabella_status === "1034" ||
            item.falabella_status === "1036" ||
            item.falabella_status === "1041" ||
            item.falabella_status === "1031" ||
            item.falabella_status === "1081" ||
            item.falabella_status === "1037" ||
            item.falabella_status === "1038" ||
            item.falabella_status === "1029" ||
            item.falabella_status === "1046" ||
            item.falabella_status === "1081" ||
            item.falabella_status === "1018" ||
            item.falabella_status === "1023" ||
            item.falabella_status === "1" ||
            item.falabella_status === "1002" ||
            item.falabella_status === "1005" ||
            item.falabella_status === "1006" ||
            item.falabella_status === "1007" ||
            item.falabella_status === "1008" ||
            item.falabella_status === "1009" ||
            item.falabella_status === "1017" ||
            item.falabella_status === "1019" ||
            item.falabella_status === "1022" ||
            item.falabella_status === "1023" ||
            item.falabella_status === "1027" ||
            item.falabella_status === "1046"
        );
        ////console.log("filtrarExcepciones", filtrarExcepciones);
        ////console.log("SinExcepciones", SinExcepciones);

        //call matrix distance bicci

        let objRequestOrigin = {
          id: "origin",
          geoRef: {
            latitud: -33.4457341,
            longitud: -70.6368104,
          },
        };
        let objRequestDestination = {
          id: "destination",
          geoRef: {
            latitud: -33.4457341,
            longitud: -70.6368104,
          },
        };

        for (let i = 0; i < SinExcepciones.length; i++) {
          //console.log(SinExcepciones[i]);
          let objRequest = {
            id: SinExcepciones[i].id,
            geoRef: {
              latitud: SinExcepciones[i].toAddressLocation.latitude,
              longitud: SinExcepciones[i].toAddressLocation.longitude,
            },
          };
          requestWaitPonint.push(objRequest);
        }

        //  console.log("requestWaitPonint", requestWaitPonint);
        // console.log("objRequestOrigin", objRequestOrigin);
        // console.log("objRequestDestination", objRequestDestination);

        let responseMatrixDistances = await getMatrixDistanceBicci(
          requestWaitPonint,
          objRequestOrigin,
          objRequestDestination
        );
        // console.log("responseMatrixDistances", responseMatrixDistances);
        if (
          responseMatrixDistances.pointOrdersData &&
          SinExcepciones &&
          responseMatrixDistances.pointOrdersData[0].hasOwnProperty("position")
        ) {
          for (
            let n = 0;
            n < responseMatrixDistances.pointOrdersData.length;
            n++
          ) {
            let socf = responseMatrixDistances.pointOrdersData[n].id;
            for (let i = 0; i < SinExcepciones.length; i++) {
              if (socf === SinExcepciones[i].id) {
                //console.log("Load la posicion ", socf);
                SinExcepciones[i].position =
                  responseMatrixDistances.pointOrdersData[n].position;
                break; // Se encontró la correspondencia, se puede salir del bucle interno
              }
            }
          }

          SinExcepciones.sort((a, b) => a.position - b.position);

          const groupsOrderedStops = [];
          const tamanoGrupo = 23;
          let indiceInicio = 0;

          while (indiceInicio < SinExcepciones.length) {
            const routeSeccion = SinExcepciones.slice(
              indiceInicio,
              indiceInicio + tamanoGrupo
            );
            groupsOrderedStops.push(routeSeccion);
            indiceInicio += tamanoGrupo;
          }

          let arrayCurrentListGroups = [];
          let waypointsContGroups = [];
          for (let i = 0; i < groupsOrderedStops.length; i++) {
            let waypointsCont = [];
            let arrayCurrentList = [];
            let dataForRoute = groupsOrderedStops[i];
            dataForRoute.sort((a, b) => a.position - b.position);
            for (let n = 0; n < dataForRoute.length; n++) {
              const wayPoint = {
                stopover: true,
                location: {
                  lat: dataForRoute[n].toAddressLocation.latitude,
                  lng: dataForRoute[n].toAddressLocation.longitude,
                },
              };
              arrayCurrentList.push(dataForRoute[n].id);
              waypointsCont.push(wayPoint);
            }

            arrayCurrentListGroups.push(arrayCurrentList);
            waypointsContGroups.push(waypointsCont);
          }

          // console.log("Data agrupada para creacion de ruta");
          // console.log(waypointsContGroups);
          // console.log(arrayCurrentListGroups);

          //  console.clear();
          function espera() {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                //console.log("Procesando...");
                resolve();
              }, 2000);
            });
          }
          await espera();

          setDataOrdesBicciByRider(SinExcepciones);
          setDataOrdesBicciByRiderGroups(groupsOrderedStops);
          setIsPointGroups(responseMatrixDistances.isPointGroups);
          setDataOrdersExcepcions(filtrarExcepciones);
          setDataOrdersDelivery(odrdesDelivery);
          setdataOrdesForRouteGoogleServicesRoutes(waypointsContGroups);
          setdataOrdesForRouteIds(arrayCurrentListGroups);
        } else {
          // Manejar el caso en el que no se obtengan los datos necesarios
        }

        // try {
        //   let responseMatrixDistances = await getMatrixDistanceBicci(
        //     requestWaitPonint,
        //     objRequestOrigin,
        //     objRequestDestination
        //   );

        //   if (responseMatrixDistances.pointOrdersData && SinExcepciones) {
        //     //console.log(responseMatrixDistances.pointOrdersData);
        //     //console.log(SinExcepciones);

        //     if (
        //       responseMatrixDistances.pointOrdersData[0].hasOwnProperty(
        //         "position"
        //       )
        //     ) {
        //       //console.log("Posee la posicion ");
        //     } else {
        //       //console.log("No Posee la posicion ");
        //     }

        //     async function loaderData() {
        //       for (
        //         let n = 0;
        //         n < responseMatrixDistances.pointOrdersData.length;
        //         n++
        //       ) {
        //         for (let i = 0; i < SinExcepciones.length; i++) {
        //           let socf = responseMatrixDistances.pointOrdersData[n].id;
        //           if (socf === SinExcepciones[i].id) {
        //             //console.log("Load la posicion ");
        //             SinExcepciones[i].position =
        //               responseMatrixDistances.pointOrdersData[n].position;
        //           }
        //         }
        //       }
        //     }

        //     async function chargePositions() {
        //       const groupsOrderedStops = [];
        //       const tamanoGrupo = 25;
        //       let indiceInicio = 0;

        //       while (indiceInicio < SinExcepciones.length) {
        //         const routeSeccion = SinExcepciones.slice(
        //           indiceInicio,
        //           indiceInicio + tamanoGrupo
        //         );
        //         groupsOrderedStops.push(routeSeccion);
        //         indiceInicio += tamanoGrupo;
        //       }
        //       //console.log("groupsOrderedStops", groupsOrderedStops);
        //       setDataOrdesBicciByRiderGroups(groupsOrderedStops);
        //     }

        //     await loaderData();
        //     await chargePositions();

        //     //console.log("SinExcepciones", SinExcepciones);
        //     setDataOrdesBicciByRider(SinExcepciones);
        //     setIsPointGroups(responseMatrixDistances.isPointGroups);

        //     setDataOrdersExcepcions(filtrarExcepciones);
        //     setDataOrdersDelivery(odrdesDelivery);
        //   } else {
        //     // Manejar el caso en el que no se obtengan los datos necesarios
        //   }
        // } catch (error) {
        //   //console.log(error);
        // }
      }

      await initMerge(dataOrder.data);
    } catch (error) {
      ////console.log(error);
    }
  };

  const findRiderDocumenttRoute = async (type) => {
    ////console.log(type);
    try {
      let collectionRef = collection(db, process.env.REACT_APP_COL_ROUTES);
      let documentReft = doc(collectionRef, type);
      let route = await getDoc(documentReft);

      const dataRoute = route.data();
      ////console.log(dataRoute);
      setDocumentRoute(dataRoute);
    } catch (error) {
      ////console.log(error);
    }
  };

  return {
    dataOrdersExcepcions,
    dataOrdesBicciByRider,
    isPointGroups,
    documentoRuote,
    dataOrderDeliverySusses,
    dataOrdesBicciByRiderGroups,
    dataOrdesForRouteGoogleServicesRoutes,
    dataOrdesForRouteIds,
  };
}

export default useGetDataDispachstGroupByDriverId;
