import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Moment from "moment";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
    border: "1px solid #000",
  },
  header: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: "center",
    fontWeight: "bold",
  },
  section: {
    marginBottom: 10,
  },
  text: {
    fontSize: 10,
    marginBottom: 4,
  },
  label: {
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 4,
  },
  tableCell: {
    fontSize: 8,
    textAlign: "center",
  },
  signatures: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  signatureBox: {
    width: "45%",
    borderTop: "1px solid #000",
    textAlign: "center",
    paddingTop: 5,
  },
  barcodeSection: {
    width: "150px",
    textAlign: "center",
    padding: 5,
  },
  separation: {
    width: "100%",
    height: "50px",
    borderTop: "1px solid #000",
    textAlign: "center",
    paddingTop: "50px",
    paddingBotton: "50px",
  },
});

const PrintDevolutions = ({ ordersData }) => {
  console.log(ordersData);
  return (
    <Document>
      {ordersData.map(({ order }, index) => (
        <Page size="A4" style={styles.page} key={index}>
          {/* Header Section */}
          <View style={styles.barcodeSection}>
            <Image
              src={"/static/images/bicciexpress.png"}
              alt=""
              style={styles.imageLogo}
            ></Image>
          </View>
          <Text style={styles.header}>
            RECIBO DE RETORNO DE FOLIO CON INCIDENCIA AL HUB BICCI
          </Text>

          {/* Details Section */}
          <View style={styles.section}>
            <Text style={styles.text}>
              <Text style={styles.label}>ID de Devolución:</Text> {order.id}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.label}>Conductor:</Text>{" "}
              {order.driverName || "No asignado"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.label}>Fecha de Devolución:</Text>{" "}
              {Moment(order.date).format("DD-MM-YYYY")}
            </Text>
          </View>

          {/* Table Section */}
          <View style={styles.table}>
            {/* Header Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>N° ITEM</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>SOC/F14/F21/F22</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>LPN/OT/OS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Cantidad</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Motivo</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Destino</Text>
              </View>
            </View>

            {/* Data Rows */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.index}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.id}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.lpn}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.cantidad}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.motivo}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.destino}</Text>
              </View>
            </View>
          </View>

          {/* Signatures Section */}
          <View style={styles.signatures}>
            <View style={styles.signatureBox}>
              <Text>Firma del Conductor</Text>
            </View>
            <View style={styles.signatureBox}>
              <Text>Firma del Encargado del HUB</Text>
            </View>
          </View>
          <View></View>
          <View style={styles.separation}></View>

          <View style={styles.barcodeSection}>
            <Image
              src={"/static/images/bicciexpress.png"}
              alt=""
              style={styles.imageLogo}
            ></Image>
          </View>
          <Text style={styles.header}>
            RECIBO DE RETORNO DE FOLIO CON INCIDENCIA AL HUB BICCI
          </Text>

          {/* Details Section */}
          <View style={styles.section}>
            <Text style={styles.text}>
              <Text style={styles.label}>ID de Devolución:</Text> {order.id}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.label}>Conductor:</Text>{" "}
              {order.driverName || "No asignado"}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.label}>Fecha de Devolución:</Text>{" "}
              {Moment(order.date).format("DD-MM-YYYY")}
            </Text>
          </View>

          {/* Table Section */}
          <View style={styles.table}>
            {/* Header Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>N° ITEM</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>SOC/F14/F21/F22</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>LPN/OT/OS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Cantidad</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Motivo</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Destino</Text>
              </View>
            </View>

            <View style={styles.signatureBox}></View>

            {/* Data Rows */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.index}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.id}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.lpn}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.cantidad}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.motivo}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{order.destino}</Text>
              </View>
            </View>
          </View>

          {/* Signatures Section */}
          <View style={styles.signatures}>
            <View style={styles.signatureBox}>
              <Text>Firma del Conductor</Text>
            </View>
            <View style={styles.signatureBox}>
              <Text>Firma del Encargado del HUB</Text>
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PrintDevolutions;
