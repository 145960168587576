import React, { useCallback, useMemo, useRef, useState } from "react";
import OpenLayersMaps from "../../../components/OpenLayersMaps/OpenLayersMaps";
import { useEffect } from "react";
import useGetDataDispachstByDate from "../../../hooks/getDataDispachstByDate";
import Moment from "moment";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import { LinearProgress } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";
import { Avatar, Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon,
  DrawingManager,
} from "@react-google-maps/api";
import { Box } from "@mui/material";
import FechtDataMaps from "./FechtDataMapsApi";
import mapStylesClear from "../../../components/MapsGoogle/mapStylesClear";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "react-bootstrap";
import PopUpAsignament from "../../../components/PopUpAsignament/PopUpAsignament";
import { differenceInDays } from "date-fns";
import MapComponentDistributions from "../../../components/MapComponentDistributions/MapComponentDistributions";

const EagleEyeGoogleMaps = (props) => {
  const memoizedLibraries = useMemo(
    () => ["geometry", "drawing", "places"],
    []
  );

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    memoizedLibraries,
  });

  const [companyCollaborator, setCompanyCollaborator] = useState(null);
  const [stateUser, setStateUser] = useState(null);
  const [path, setPath] = useState([]);
  const [viewDrivers, setViewDrivers] = useState(true);
  const [viewDeliverys, setViewDeliverys] = useState(true);
  const [viewExepcions, setViewViewExepcions] = useState(true);
  const [viewPendings, setViewPendings] = useState(true);
  const [viewPoligons, setViewPoligons] = useState(false);
  const [viewTools, setViewTools] = useState(false);
  const [selectedMarker, setSelectedMarker] = React.useState(null);
  const [selectedMarkerDriver, setSelectedMarkerDriver] = React.useState(null);
  const [center, setCenter] = useState({ lat: -33.448891, lng: -70.669266 });
  const [zoom, setZoom] = useState(13); // Asumiendo un zoom inicial
  const [driver, setDriver] = useState("");
  const [driverFilter, setDriverFilter] = useState([]);
  const [ordersByPoligons, setOrdersByPoligons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = React.useState(null);
  const [opemPopUpAsignament, setOpemPopUpAsignament] = useState(false);
  const [ordersForAccions, setOrdersForAccions] = useState([]);
  const [vertical, setServiceVertical] = useState("all");

  const pines = true;

  const user = useSelector((store) => store.usuario.user);
  const { userdata } = useObtenerDatosdeCliente(user.uid);

  const { dataMerge, metricsOps, ridersDataByCompany } = FechtDataMaps(
    stateUser,
    vertical
  );

  const memoizedData = useMemo(() => {
    return { dataMerge, metricsOps, ridersDataByCompany };
  }, [dataMerge, metricsOps, ridersDataByCompany]);

  const google = window.google;

  useEffect(() => {
    console.log("Algo cambio ", dataMerge);
  }, [dataMerge]);

  useEffect(() => {
    if (userdata) {
      setStateUser(userdata);
      setCompanyCollaborator(userdata.companyCollaborator);
    }
  }, [userdata]);

  useEffect(() => {}, [setSelectedMarker]);

  const setSeleccionByMapDataWaitPoints = () => {};

  const mapRef = useRef();
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const onPolygonComplete = (polygon) => {
    polygonRef.current = polygon;
    const newCoordinates = polygon
      .getPath()
      .getArray()
      .map((latLng) => {
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    setPath(newCoordinates);
    polygon.setDraggable(true);
    polygon.setEditable(true);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("dragend", onEdit);
    polygon.addListener("mouseup", onEdit);
    polygon.addListener("onLoad", onLoad);
    polygon.addListener("onUnmount", onUnmount);
    // setSelectedPolygon is not defined in your provided code
    setSelectedPolygon({
      polygon: polygon,
      coordinates: newCoordinates,
    });
  };

  const onSelectPolygon = (polygon) => {
    polygonRef.current = polygon;
    setSelectedPolygon({
      polygon: polygonRef.current,
      coordinates: polygonRef.path,
    });
  };

  React.useEffect(() => {
    if (path && google) {
      findPoints();
    }
  }, [path]);

  async function findPoints() {
    //console.log(path);
    const polygon = new google.maps.Polygon({
      paths: path,
    });
    if (memoizedData.dataMerge) {
      const markersInPolygon = memoizedData.dataMerge.filter((marker) => {
        // console.log(marker);
        try {
          const markerPosition = new google.maps.LatLng(
            marker.toAddressLocation.latitude,
            marker.toAddressLocation.longitude
          );

          const isInside = google.maps.geometry.poly.containsLocation(
            markerPosition,
            polygon
          );
          return isInside;
        } catch (error) {
          // console.log(`${marker.id} no posee geo`);
        }
      });

      if (markersInPolygon) {
        markersInPolygon.forEach((marker) => {
          // Imprimir más información del marcador si es necesario
        });
        setOrdersByPoligons(markersInPolygon);
        const orderIdsWithF = markersInPolygon.map((order) => `F${order.id}`);
        setOrdersForAccions(orderIdsWithF);
      } else {
        // console.log("No detecto nuevas marcas");
      }
    } else {
      //      console.log("No detecto nuevas puntos den props");
    }
  }

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    } else {
    }
  }, []);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const [polygonOptions, setPolygonOptions] = React.useState({
    editable: true,
    draggable: true,
    // onMouseUp and onDragEnd should be function handlers, not objects
    // onLoad and onUnmount are not valid polygon options
    strokeWeight: 1,
    fillOpacity: 0.3,
    fillColor: "#591e8f",
    strokeColor: "#591e8f",
    strokeOpacity: 0.8,
  });

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    let currentCenter = map.getCenter();
    map.addListener("idle", () => {
      let newCenter = map.getCenter();
      // Comprueba si el centro realmente ha cambiado antes de actualizar el estado
      if (
        newCenter.lat() !== currentCenter.lat() ||
        newCenter.lng() !== currentCenter.lng()
      ) {
        setCenter({
          lat: newCenter.lat(),
          lng: newCenter.lng(),
        });
        currentCenter = newCenter; // Actualiza la referencia del centro actual
      }
    });

    // Haz algo similar para el zoom si es necesario
    let currentZoom = map.getZoom();
    map.addListener("zoom_changed", () => {
      let newZoom = map.getZoom();
      if (newZoom !== currentZoom) {
        setZoom(newZoom);
        currentZoom = newZoom; // Actualiza la referencia del zoom actual
      }
    });
  }, []);

  const handleChange = (e) => {
    //console.log(e.target.value);
    setDriver(e.target.value);
    let seleccionRider = dataMerge.filter(
      (item) => item.driverID === e.target.value
    );
    // console.log(seleccionRider);
    setDriverFilter(seleccionRider);
  };

  const handleChangeService = (e) => {
    setServiceVertical(e.target.value);
  };

  if (loadError) {
    return "Error";
  }
  if (!isLoaded) {
    return "Loading...";
  }

  let viewRoutesAgupations = false;

  return memoizedData ? (
    <div>
      <Box>
        {ridersDataByCompany ? (
          <PopUpAsignament
            opemPopUpAsignament={opemPopUpAsignament}
            setOpemPopUpAsignament={setOpemPopUpAsignament}
            ordersForAccions={ordersForAccions}
            ridersDataByCompany={ridersDataByCompany}
          ></PopUpAsignament>
        ) : null}
        <AppBar position="static">
          <Toolbar sx={{ background: "#591e8f" }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, background: "#591e8f" }}
            >
              Mapa de Operacíon Integración Bicci Bicci - Riders
            </Typography>
            <FormControl sx={{ minWidth: "150px" }}>
              <InputLabel id="select-label" sx={{ color: "#fff" }}>
                Servicio
              </InputLabel>
              <Select
                labelId="select-label"
                id="demo-simple-select"
                value={vertical}
                label="Servicio"
                onChange={handleChangeService}
                sx={{ color: "#fff" }}
              >
                <MenuItem key={"all"} value={"all"}>
                  Todos
                </MenuItem>
                <MenuItem key={"sd"} value={"SD"}>
                  SAME DAY
                </MenuItem>
                <MenuItem key={"hd"} value={"HD"}>
                  HOME DELIVERY
                </MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <Button onClick={() => setDriverFilter([])}>Limpiar</Button>
            </FormGroup>
            <FormControl sx={{ minWidth: "250px" }}>
              <InputLabel id="select-label" sx={{ color: "#fff" }}>
                Conductor
              </InputLabel>
              <Select
                labelId="select-label"
                id="demo-simple-select"
                value={driver}
                label="Conductor"
                onChange={handleChange}
                sx={{ color: "#fff" }}
              >
                {metricsOps.map((item, index) => (
                  <MenuItem key={index} value={item.driverID}>
                    {item.driverName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewDrivers}
                    onChange={() => setViewDrivers(!viewDrivers)}
                    aria-label="login switch"
                  />
                }
                label={viewDrivers ? "Riders" : "Riders"}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewPendings}
                    onChange={() => setViewPendings(!viewPendings)}
                    aria-label="login switch"
                  />
                }
                label={viewPendings ? "Pendientes" : "Pendientes"}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewDeliverys}
                    onChange={() => setViewDeliverys(!viewDeliverys)}
                    aria-label="login switch"
                  />
                }
                label={viewDeliverys ? "Entregados" : "Entregados"}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewExepcions}
                    onChange={() => setViewViewExepcions(!viewExepcions)}
                    aria-label="login switch"
                  />
                }
                label={viewExepcions ? "Excepciones" : "Excepciones"}
              />
            </FormGroup>

            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewPoligons}
                    onChange={() => setViewPoligons(!viewPoligons)}
                    aria-label="login switch"
                  />
                }
                label={viewPoligons ? "Poligonos" : "Poligonos"}
              />
            </FormGroup> */}

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={viewTools}
                    onChange={() => setViewTools(!viewTools)}
                    aria-label="login switch"
                  />
                }
                label={viewTools ? "Herramientas" : "Herramientas"}
              />
            </FormGroup>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "450px",
          backgroundColor: "#F8F8F8",
          padding: "0px",
        }}
      >
        {selectedMarkerDriver ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
              borderBottomRightRadius: "10px",
              boxShadow: "2px 2px 5px rgba(0,0,0,0.1)",
            }}
          >
            <Avatar
              sx={{ bgcolor: "#f8f8f8", width: 70, height: 70 }}
              src={
                selectedMarkerDriver.avatar ? selectedMarkerDriver.avatar : ""
              }
              alt=""
            ></Avatar>
            <Typography>{selectedMarkerDriver.name}</Typography>
            {/* <Typography>{selectedMarkerDriver.id}</Typography> */}
            <Typography>{selectedMarkerDriver.email}</Typography>
            <Typography>{selectedMarkerDriver.dni}</Typography>
            <Typography>{selectedMarkerDriver.bicciNumber}</Typography>
            <Typography>{selectedMarkerDriver.phone}</Typography>
            <Typography>Carga : {driverFilter.length} </Typography>
            <Button onClick={() => setSelectedMarkerDriver(null)}>
              Cerrar
            </Button>
          </Box>
        ) : null}

        {ordersByPoligons && ordersByPoligons.length > 0 ? (
          <Box
            sx={{
              minWidth: "200px",
              minHeight: "200px",
              maxHeight: "80%",
              overflowY: "scroll",
              height: "auto",
              width: "auto",
              float: "left",
              position: "absolute",
              backgroundColor: "#fff",
              zIndex: 800,
              padding: "1%",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "600", color: "#030303" }}
            >
              Selección en polígono
            </Typography>
            {ordersByPoligons.map((item) => (
              <Stack key={item.id} sx={{ width: "100%" }}>
                <Chip size="small" label={`ID: ${item.id}`} sx={{ mb: 1 }} />
              </Stack>
            ))}

            <Button onClick={() => setOrdersByPoligons([])}>Cerrar</Button>
            <Button onClick={() => setOpemPopUpAsignament(true)}>
              Asignar Bicci Riders
            </Button>
            <Button onClick={() => console.log("asignar")}>
              Asignar Bicci Express
            </Button>
          </Box>
        ) : null}

        {viewRoutesAgupations ? (
          <>
            {" "}
            {memoizedData && driverFilter.length === 0 ? (
              <GoogleMap
                id="mapControl3"
                // mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={center}
                onLoad={onMapLoad}
              >
                {viewTools ? (
                  <DrawingManager
                    onPolygonComplete={onPolygonComplete}
                    defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                    options={{
                      drawingControl: true,
                      drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                          // google.maps.drawing.OverlayType.CIRCLE,
                          // google.maps.drawing.OverlayType.RECTANGLE,
                          google.maps.drawing.OverlayType.POLYGON,
                        ],
                      },
                      polygonOptions: polygonOptions,
                    }}
                  ></DrawingManager>
                ) : null}

                {viewDrivers &&
                  memoizedData?.ridersDataByCompany?.map((rider, index) => {
                    if (rider.driverLocation && rider.online === true) {
                      return (
                        <Marker
                          key={index}
                          position={{
                            lat: rider.driverLocation.latitude,
                            lng: rider.driverLocation.longitude,
                          }}
                          onClick={() => {
                            setSelectedMarkerDriver(rider);
                          }}
                          onLoad={(markerInstance) => {
                            const customIcon = {
                              url: "/static/images/conductor.png", // URL de la imagen que quieres usar
                              scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                              anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                            };
                            markerInstance.setIcon(customIcon);
                          }}
                          // Aquí puedes agregar más propiedades al marcador si es necesario
                        />
                      );
                    } else {
                      return null; // Si no hay datos de localización, retorna null
                    }
                  })}
                {viewDeliverys &&
                  memoizedData?.dataMerge?.map((order, index) => {
                    if (order.toAddressLocation) {
                      if (
                        order.toAddressLocation &&
                        order.falabella_status === "99"
                      ) {
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casita.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        );
                      } else {
                        return null; // Si no hay datos de localización, retorna null
                      }
                    }
                  })}
                {viewPendings &&
                  memoizedData?.dataMerge?.map((order, index) => {
                    if (order.toAddressLocation) {
                      if (
                        order.falabella_status === "1060" ||
                        order.falabella_status === "1061" ||
                        order.falabella_status === "1058"
                      ) {
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casitaAzul.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        );
                      } else {
                        return null; // Si no hay datos de localización, retorna null
                      }
                    }
                  })}

                {viewExepcions &&
                  memoizedData?.dataMerge?.map((order, index) => {
                    if (order.toAddressLocation) {
                      if (
                        order.falabella_status !== "1060" &&
                        order.falabella_status !== "1061" &&
                        order.falabella_status !== "99" &&
                        order.falabella_status !== "1058"
                      ) {
                        const currentDate = new Date();
                        const createDate = order.createdAt
                          ? new Date(order.createdAt.seconds * 1000)
                          : currentDate;
                        const updateDate = order.updatedAt
                          ? new Date(order.updatedAt.seconds * 1000)
                          : currentDate;
                        const delayDays = differenceInDays(
                          updateDate,
                          createDate
                        );

                        const isDelayed = delayDays > 0;
                        return isDelayed ? (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            label={{
                              text: `Retraso ${delayDays} días`,
                              color: "#F8F8F8",
                              className: "my-custom-label-ping",
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casitaNaranja.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        ) : (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casitaNaranja.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        );
                      } else {
                        return null; // Si no hay datos de localización, retorna null
                      }
                    }
                  })}

                {selectedMarker ? (
                  <>
                    {
                      <InfoWindow
                        position={{
                          lat: selectedMarker.toAddressLocation.latitude,
                          lng: selectedMarker.toAddressLocation.longitude,
                        }}
                        onCloseClick={() => {
                          setSelectedMarker("");
                        }}
                      >
                        <div>
                          <span
                            onClick={() => setSelectedMarker(null)}
                            className="posditonCloseInfo"
                          >
                            x
                          </span>
                          <a
                            className="btn-bicci-prupura-color"
                            href={`https://bicciexpress.cl/dashboard/dispatch/details/F${selectedMarker.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h5>ID: {selectedMarker.id}</h5>
                          </a>

                          <div>
                            <a
                              className="nonstyle"
                              href={`tel:9${selectedMarker.customer_phone}`}
                            >
                              Teléfono: {selectedMarker.customer_phone}
                            </a>
                          </div>
                          <div>
                            <a
                              className="nonstyle"
                              href={`https://api.whatsapp.com/send?phone=${selectedMarker.customer_phone}&text=Soporte%20BICCI%20:`}
                            >
                              WhatsApp: {selectedMarker.customer_phone}
                            </a>
                          </div>
                          <div>Nombre : {selectedMarker.customer_name}</div>
                          <div>Teléfono : {selectedMarker.customer_phone}</div>
                          <div>Destino : {selectedMarker.address}</div>
                          <div>Conductor : {selectedMarker.driverName}</div>
                          <div>
                            Observación :{" "}
                            {selectedMarker.observationAdmin?.[0]
                              ? selectedMarker.observationAdmin[0]
                              : "Sin comentario"}
                          </div>
                        </div>
                      </InfoWindow>
                    }
                  </>
                ) : null}
              </GoogleMap>
            ) : (
              <GoogleMap
                id="mapControl3"
                // mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={center}
                onLoad={onMapLoad}
              >
                {viewTools ? (
                  <DrawingManager
                    onPolygonComplete={onPolygonComplete}
                    defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
                    options={{
                      drawingControl: true,
                      drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                          // google.maps.drawing.OverlayType.CIRCLE,
                          // google.maps.drawing.OverlayType.RECTANGLE,
                          google.maps.drawing.OverlayType.POLYGON,
                        ],
                      },
                      polygonOptions: polygonOptions,
                    }}
                  ></DrawingManager>
                ) : null}

                {viewDrivers &&
                  memoizedData?.ridersDataByCompany?.map((rider, index) => {
                    if (rider.id === driver) {
                      return (
                        <Marker
                          key={index}
                          position={{
                            lat: rider.driverLocation.latitude,
                            lng: rider.driverLocation.longitude,
                          }}
                          onClick={() => {
                            setSelectedMarkerDriver(rider);
                          }}
                          onLoad={(markerInstance) => {
                            const customIcon = {
                              url: "/static/images/conductor.png", // URL de la imagen que quieres usar
                              scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                              anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                            };
                            markerInstance.setIcon(customIcon);
                          }}
                          // Aquí puedes agregar más propiedades al marcador si es necesario
                        />
                      );
                    } else {
                      return null; // Si no hay datos de localización, retorna null
                    }
                  })}
                {viewDeliverys &&
                  driverFilter?.map((order, index) => {
                    if (order.toAddressLocation) {
                      if (
                        order.toAddressLocation &&
                        order.falabella_status === "99"
                      ) {
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casita.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        );
                      } else {
                        return null; // Si no hay datos de localización, retorna null
                      }
                    }
                  })}
                {viewPendings &&
                  driverFilter?.map((order, index) => {
                    if (order.toAddressLocation) {
                      if (
                        order.falabella_status === "1060" ||
                        order.falabella_status === "1061" ||
                        order.falabella_status === "1058"
                      ) {
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casitaAzul.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        );
                      } else {
                        return null; // Si no hay datos de localización, retorna null
                      }
                    }
                  })}

                {viewExepcions &&
                  driverFilter?.map((order, index) => {
                    if (order.toAddressLocation) {
                      if (
                        order.falabella_status !== "1060" &&
                        order.falabella_status !== "1061" &&
                        order.falabella_status !== "99" &&
                        order.falabella_status !== "1058"
                      ) {
                        return (
                          <Marker
                            key={index}
                            position={{
                              lat: order.toAddressLocation.latitude,
                              lng: order.toAddressLocation.longitude,
                            }}
                            onClick={() => {
                              setSelectedMarker(order);
                            }}
                            onLoad={(markerInstance) => {
                              const customIcon = {
                                url: "/static/images/casitaNaranja.png", // URL de la imagen que quieres usar
                                scaledSize: new window.google.maps.Size(50, 50), // Tamaño al que se escalará la imagen
                                anchor: new window.google.maps.Point(
                                  17.5,
                                  17.5
                                ), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                              };
                              markerInstance.setIcon(customIcon);
                            }}
                          />
                        );
                      } else {
                        return null; // Si no hay datos de localización, retorna null
                      }
                    }
                  })}

                {selectedMarker ? (
                  <>
                    {
                      <InfoWindow
                        position={{
                          lat: selectedMarker.toAddressLocation.latitude,
                          lng: selectedMarker.toAddressLocation.longitude,
                        }}
                        onCloseClick={() => {
                          setSelectedMarker("");
                        }}
                      >
                        <div>
                          <span
                            onClick={() => setSelectedMarker(null)}
                            className="posditonCloseInfo"
                          >
                            x
                          </span>
                          <a
                            className="btn-bicci-prupura-color"
                            href={`https://bicciexpress.cl/dashboard/dispatch/details/F${selectedMarker.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h5>ID: {selectedMarker.id}</h5>
                          </a>

                          <div>
                            <a
                              className="nonstyle"
                              href={`tel:9${selectedMarker.customer_phone}`}
                            >
                              Teléfono: {selectedMarker.customer_phone}
                            </a>
                          </div>
                          <div>
                            <a
                              className="nonstyle"
                              href={`https://api.whatsapp.com/send?phone=${selectedMarker.customer_phone}&text=Soporte%20BICCI%20:`}
                            >
                              WhatsApp: {selectedMarker.customer_phone}
                            </a>
                          </div>
                          <div>Nombre : {selectedMarker.customer_name}</div>
                          <div>Teléfono : {selectedMarker.customer_phone}</div>
                          <div>Destino : {selectedMarker.address}</div>
                          <div>Conductor : {selectedMarker.driverName}</div>
                          <div>
                            Observación :{" "}
                            {selectedMarker.observationAdmin?.[0]
                              ? selectedMarker.observationAdmin[0]
                              : "Sin comentario"}
                          </div>
                        </div>
                      </InfoWindow>
                    }
                  </>
                ) : null}
              </GoogleMap>
            )}
          </>
        ) : (
          <>
            {console.log(memoizedData)}
            {dataMerge.length > 0 ? (
              <>
                <MapComponentDistributions
                  ridersDataByCompany={ridersDataByCompany}
                  dataMerge={dataMerge}
                  isLoaded={isLoaded}
                  center={center}
                  zoom={zoom}
                ></MapComponentDistributions>
              </>
            ) : (
              <LinearProgress></LinearProgress>
            )}
          </>
        )}
      </Box>
    </div>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default EagleEyeGoogleMaps;
