import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";

const DocumentsDialog = ({ open, onClose, setManifestData }) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  // Columnas del DataGrid
  const columns = [
    { field: "name", headerName: "Nombre", width: 250 },
    { field: "startDate", headerName: "Fecha de Inicio", width: 200 },
    { field: "endDate", headerName: "Fecha de Término", width: 200 },
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleEdit(params.row.id)}
          >
            Editar
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Eliminar
          </Button>
        </Box>
      ),
    },
  ];

  // Función para cargar los documentos desde Firebase
  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const collectionRef = collection(db, "xpress_manifestDevolutions");
      const q = query(collectionRef, where("isDeleted", "==", false));
      const querySnapshot = await getDocs(q);

      const docs = querySnapshot.docs.map((doc) => {
        const data = doc.data();

        return {
          id: doc.id,
          name: data.title || "Sin nombre",
          startDate: data.date ? format(data.date.toDate(), "dd-MM-yy") : "N/A",
          endDate: data.update
            ? format(data.update.toDate(), "dd-MM-yy")
            : "N/A",
          ...data,
        };
      });

      setDocuments(docs);
    } catch (error) {
      console.error("Error al cargar documentos:", error);
    } finally {
      setLoading(false);
    }
  };

  // Cargar documentos al abrir el diálogo
  useEffect(() => {
    if (open) fetchDocuments();
  }, [open]);

  const handleEdit = (id) => {
    console.log("Editar documento con ID:", id);
    console.log(documents);
    // Buscar el documento seleccionado
    const documentSelected = documents.find((item) => item.id === id);

    // Validar si el documento existe
    if (!documentSelected) {
      console.error(`No se encontró el documento con ID: ${id}`);
      return;
    }

    // Actualizar el manifiesto padre con los datos del documento seleccionado
    setManifestData({
      id: documentSelected.id,
      companyId: documentSelected.companyId || "falabella",
      createBy: documentSelected.createBy || "userID",
      date: documentSelected.date || Timestamp.now(),
      isActive:
        documentSelected.isActive !== undefined
          ? documentSelected.isActive
          : true,
      isDeleted:
        documentSelected.isDeleted !== undefined
          ? documentSelected.isDeleted
          : false,
      orders: documentSelected.orders || [],
      title: documentSelected.name || "Sin título",
    });

    console.log("Documento cargado en el manifiesto:", documentSelected);

    onClose(); // Cierra el diálogo
  };

  const handleDelete = async (id) => {
    console.log("Eliminar documento:", id);
    try {
      const docRef = doc(db, "xpress_manifestDevolutions", id);
      await setDoc(docRef, { isDeleted: true }, { merge: true });
      console.log(`Documento ${id} marcado como eliminado.`);
      // Actualiza la lista local sin el documento eliminado
      setDocuments((prev) => prev.filter((doc) => doc.id !== id));
    } catch (error) {
      console.error("Error al eliminar documento:", error);
    }
    // Implementa la lógica para eliminar el documento
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Documentos de Devoluciones</DialogTitle>
      <DialogContent>
        <Box sx={{ height: 500, width: "100%" }}>
          <DataGrid
            rows={documents}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            loading={loading}
            pagination
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentsDialog;
