import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import "@reach/combobox/styles.css";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { LinearProgress } from "@mui/material";

const SelectorGeoRecf = (props) => {
  console.log(props);
  const [center, setCenter] = React.useState({
    lat: props.markersPending.latitude,
    lng: props.markersPending.longitude,
  });
  const [selectedLocation, setSelectedLocation] = React.useState({
    lat: props.markersPending.latitude,
    lng: props.markersPending.longitude,
  });

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  });

  useEffect(() => {}, [center, selectedLocation]);

  useEffect(() => {
    if (props.markersPending) {
      setCenter({
        lat: props.markersPending.latitude,
        lng: props.markersPending.longitude,
      });
      setSelectedLocation({
        lat: props.markersPending.latitude,
        lng: props.markersPending.longitude,
      });
    }
  }, [props && props.markersPending]);

  const handleMapClick = (event) => {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    props.setNewLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  function Search() {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 43.6532, lng: () => -79.3832 },
        radius: 10 * 1000,
        componentRestrictions: {
          country: "Cl",
        },
      },
    });
    // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
    const handleInput = (e) => {
      setValue(e.target.value);
    };
    const handleSelect = async (address) => {
      setValue(address, false);
      clearSuggestions();
      try {
        const results = await getGeocode({ address });

        const { lat, lng } = await getLatLng(results[0]);
        setSelectedLocation({ lat: lat, lng: lng });
        panTo({ lat: lat, lng: lng });
        props.setNewLocation({
          lat: lat,
          lng: lng,
        });
      } catch (error) {
        // console.log("😱 Error: ", error);
        // console.log(element);
      }
    };

    return (
      <div className="search-box-ebiex">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="input-group mb-2"
            id="imputSearchFromTo"
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Escribe una dirección de destino"
          />

          <ComboboxPopover
            className="comboboxList-routes-direcctiones"
            style={{ zIndex: 3000, backgroundColor: "#fff" }}
          >
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption
                    key={Math.random().toString()}
                    value={description}
                    className="dropdown-item dropdown-ebiex"
                  />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  return center && selectedLocation ? (
    <div>
      <div>
        <div>Bucar:</div>
        <div>
          <Search id="imputSearchFromOrigen" />
        </div>
      </div>
      <div className="mapControlStyle">
        <GoogleMap
          id="mapControl2"
          zoom={18}
          center={center}
          onLoad={onMapLoad}
          onClick={handleMapClick}
        >
          <Marker
            key={Math.random().toString()}
            position={{
              lat: parseFloat(selectedLocation.lat),
              lng: parseFloat(selectedLocation.lng),
            }}
          />
        </GoogleMap>
      </div>
    </div>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default SelectorGeoRecf;
