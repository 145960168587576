import React, { useState, useEffect, useRef } from "react";
import {
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  Timestamp,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { AppBar, Grid, Toolbar, Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Moment from "moment";
import { useOrders } from "../../hooks/useOrdersProviders/OrdersContext";
import { IconButton } from "@mui/material";
import { customLocaleText } from "../../utils/objects/CustomLocaleTextTables";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DeleteIcon from "@mui/icons-material/Delete";
import { pdf } from "@react-pdf/renderer";
import * as XLSX from "xlsx";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PrintDevolutions from "./PrintDevolutions";
import DocumentsDialog from "./DocumentsDialog";

var listadeItemsNoMach = [];

const ScannerDevoluciones = (props) => {
  const [dataSearchOrder, setDataSearch] = useState("");
  const [dispachtHistory, setDispachtHistory] = useState(null);
  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    useState(false);
  const [routesDataOrdersForAccions, setRoutesDataOrdersForAccions] = useState(
    []
  );
  const [routesOrdersForAccions, setRoutesOrdersForAccions] = useState([]);
  const [prefixes, setPrefixes] = useState([]);
  const [progressValue, setProgressValue] = useState([]);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [viewTools, setviewTools] = React.useState(true);
  const [ordersView, setOrdersView] = React.useState([]);
  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [title, setTitle] = useState(Moment(Date.now()).format("YYYY-MM-DD"));
  const [bulkEditData, setBulkEditData] = useState({
    dateDevolucion: "",
    Tienda: "",
    patente: "",
    destino: "",
    obs: "",
  });
  const [manifestData, setManifestData] = useState({
    id: "",
    companyId: null,
    createBy: null,
    date: Timestamp.now(),
    isActive: true,
    isDeleted: false,
    title: "",
    orders: [],
  });

  const [editEscannerDialogOpen, setEditEscannerDialogOpen] = useState(false);
  const [dialogOpenDocumentsDevolutions, setDialogOpenDocumentsDevolutions] =
    useState(false);
  const [scannerInput, setScannerInput] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const typingTimeoutRef = useRef(null);

  const { companiesData } = useOrders({ initDateInitial, endDateInitial });

  useEffect(() => {
    if (companiesData) {
      //    console.log(companiesData);
      // const prefixes = companiesData
      //   .filter((item) => item.prefix)
      //   .map((item) => item.prefix);
      const prefixes = ["F"];
      setPrefixes(prefixes);
    }
  }, [companiesData]);

  useEffect(() => {
    if (ordersView.length > 0) {
      upDateTable(ordersView);
    }
  }, [ordersView]);

  useEffect(() => {
    console.log("Documento recibido: ", manifestData);
    setTitle(manifestData.title);
    setOrdersView(manifestData.orders);
  }, [manifestData]);

  useEffect(() => {
    if (editEscannerDialogOpen) {
      setIsScanning(true);
      window.addEventListener("keydown", handleScannerInput);
    }
    return () => {
      setIsScanning(false);
      window.removeEventListener("keydown", handleScannerInput);
    };
  }, [editEscannerDialogOpen, isScanning]);

  const handleScannerInput = (e) => {
    console.log(e.key);
    if (isScanning) {
      setScannerInput((prevInput) => {
        let newInput = prevInput;

        // Evitar agregar la palabra "Enter" al input
        if (e.key !== "Enter") {
          newInput += e.key;
        }

        console.log(newInput); // Mostrar el valor actualizado

        // Limpiar el temporizador anterior si existe
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }

        // Si se presiona "Enter", realizar la búsqueda inmediatamente
        if (e.key === "Enter") {
          handleSeach(newInput.trim()); // Realiza la búsqueda
          return ""; // Limpiar el input
        } else {
          // Iniciar un nuevo temporizador de 1.5 segundos
          typingTimeoutRef.current = setTimeout(() => {
            handleSeach(newInput.trim());
            setScannerInput(""); // Limpiar el input
          }, 1500);

          return newInput; // Actualizar el estado con el nuevo valor
        }
      });
    }
  };

  const handleSeach = async (code) => {
    if (code && code.trim() !== "") {
      console.log("Buscando código:", code);
      let result = await findOrdesrDispacht(code, prefixes);
    } else {
      console.log("Parámetro inválido");
    }
  };

  const tryGetDoc = async (collection, id) => {
    console.log(collection, id);
    try {
      const orderRef = doc(db, collection, id);
      const docSnapshot = await getDoc(orderRef);
      if (docSnapshot.exists()) {
        console.log("encontrado:", id, " en : ", collection);
      }
      return docSnapshot.exists() ? docSnapshot.data() : null;
    } catch (error) {
      console.log(error);
    }
  };

  const tryGetDocHistory = async (collectionSeacrh, id) => {
    const orderRef = doc(db, collectionSeacrh, id);
    const statusDocs = collection(orderRef, "status");
    const dataallOrdersfind5 = await getDocs(statusDocs);
    const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    datadocuments.sort((a, b) =>
      a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
    );

    return datadocuments.length > 0 ? datadocuments : [];
  };

  const findOrdesrDispacht = async (id, prefixes) => {
    setDataSearch(null);
    let foundDocumentData = null;

    const collections = [
      process.env.REACT_APP_COL_FALABELLA_ORDERS,
      process.env.REACT_APP_COL_USERS_ORDERS,
      // process.env.REACT_APP_COL_ORDERS,
    ];

    try {
      for (let collectionArray of collections) {
        for (let prefix of prefixes) {
          let idWithPrefix = prefix + id;

          // Busca el documento con el prefijo correspondiente
          let documentData = await tryGetDoc(collectionArray, idWithPrefix);

          if (documentData) {
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(20);

          // Si no se encuentra, busca sin el prefijo
          documentData = await tryGetDoc(collectionArray, id);
          if (documentData) {
            foundDocumentData = documentData;
            break;
          }

          setProgressValue(30);
          setProgressValue(40);

          if (documentData) {
            foundDocumentData = documentData;
            break;
          }
        }

        // Si se encontró un documento, procesa y rompe el bucle exterior
        if (foundDocumentData) {
          console.log("Continua el proceso por el history");
          let idWithPrefix2 = "F" + id;

          let documentBicciData = await tryGetDoc(
            process.env.REACT_APP_COL_USERS_ORDERS,
            idWithPrefix2
          );

          console.log(documentBicciData?.driverName);
          foundDocumentData.driverName = documentBicciData?.driverName || "";

          let documentDataHistory = await tryGetDocHistory(
            collectionArray,
            foundDocumentData.id
          );

          console.log(documentDataHistory);

          let motivos = {
            1: "SIN MORADORES",
            1002: "DOMICILIO NO CORRESPONDE",
            1005: "PROD NO ES LO COMPRADO",
            1006: "PROD INCOMPLETO",
            1007: "PROD FALTANTE",
            1008: "PROD DANADO",
            1009: "FUERA DE HORARIO",
            1015: "DESPACHO SALIO ADELANTADO",
            1017: "NO RETIRA NO CUMPL FORMAT",
            1019: "FUERA DE COBERTURA",
            1022: "CLIENTE REALIZARA N/C",
            1030: "INCIDENCIA EN LA CALLE",
            1032: "FALTA DE INFORMACIÓN",
            1034: "PROD YA FUE RECIBIDO",
            1036: "DIFICULTAD ACCESO DESTINO",
            1041: "CLIENTE RECHAZA ENVIO",
            1029: "PROCESO DE LI",
            1081: "RETORNADO A ORIGEN",
            1018: "NO CONOCEN AL CLIENTE",
          };

          if (documentDataHistory.length > 0) {
            let lastMatchedMotivo = "MOTIVO NO ENCONTRADO";

            // Recorrer el historial en orden inverso
            for (let i = documentDataHistory.length - 1; i >= 0; i--) {
              const status = documentDataHistory[i].status;

              // Verificar si el status tiene un motivo en la lista
              if (motivos[status]) {
                lastMatchedMotivo = motivos[status];
                break; // Salir del bucle al encontrar el primer motivo coincidente
              }
            }

            // Asignar el motivo encontrado (si lo hay) a foundDocumentData
            foundDocumentData.motivo = lastMatchedMotivo;
            setOrdersView((preOrdersView) => [
              ...preOrdersView,
              foundDocumentData,
            ]);
            console.log("Último motivo encontrado:", lastMatchedMotivo);
          } else {
            setOrdersView((preOrdersView) => [
              ...preOrdersView,
              foundDocumentData,
            ]);
          }

          setDispachtHistory(documentDataHistory);

          setProgressValue(100);
          return; // Salir completamente de la función
        }
      }

      // Si no se encontró ningún documento, agregar el objeto "sobrante"
      const sobranteObject = {
        id: id, // SOC es el código
        motivo: "SOBRANTE",
        dateDevolucion: "", // Campos vacíos
        f12: "",
        lpn: "",
        cantidad: 0,
        Tienda: "",
        patente: "",
        destino: "",
        obs: "",
        driverName: "",
      };

      setOrdersView((preOrdersView) => [...preOrdersView, sobranteObject]);
      console.log("Se agregó el objeto sobrante:", sobranteObject);
    } catch (error) {
      console.error("Error al buscar la orden:", error);
    }
  };

  const handleViewTools = (view) => {
    setviewTools(view);
  };

  const HandleSeleccions = (ids) => {
    const selectedRowsData = ids.map((id) => rows.find((row) => row.id === id));
    setSelectedData(selectedRowsData);
    setSelectedIDs(ids);
    UpdateOrderForAcctions(ids);
    if (ids.length > 0) {
      handleViewTools(true);
    } else {
      handleViewTools(false);
    }
  };

  const UpdateOrderForAcctions = (items) => {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = []; // mergedOrders
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push(tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes(listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
      setRoutesDataOrdersForAccions(dataOrdersForAccions);
      setRoutesOrdersForAccions(ordersForAccions);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  };

  const upDateTable = (ordersView) => {
    // console.log(ordersView);
    const renderActionButtons = (params) => {
      if (params.row.id !== undefined && params.row.id !== "") {
        return (
          <Box
            sx={{
              display: "flex", // Arrange the buttons in a row
              flexDirection: "row",
              justifyContent: "space-between", // Ensure there's space between buttons
              gap: "8px", // Add some space between buttons
            }}
          >
            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Etiqueta"
              onClick={() => handlePrint(params.row)}
            >
              <QrCode2Icon />
            </IconButton>

            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Etiqueta"
              onClick={() => handleDeleted(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      }
    };

    const columnsTable = [
      {
        field: "index",
        headerName: "N° ITEM",
        width: 20,
      },
      {
        field: "dateDevolucion",
        headerName: "Fecha Devolucíon",
        width: 150,
        editable: true,
      },
      {
        field: "id",
        headerName: "SOC F14/ F21 / F22",
        width: 150,
        editable: true,
      },
      {
        field: "f12",
        headerName: "F12",
        width: 150,
        editable: true,
      },
      {
        field: "lpn",
        headerName: "LPN/OT/OS",
        width: 150,
        editable: true,
      },
      {
        field: "cantidad",
        headerName: "Cantidad Bultos",
        width: 150,
        editable: true,
      },
      {
        field: "motivo",
        headerName: "Motivo de devolución",
        width: 200,
        editable: true,
      },
      {
        field: "Tienda",
        headerName: "Tienda",
        width: 150,
        editable: true,
      },
      {
        field: "patente",
        headerName: "Patente",
        width: 100,
        editable: true,
      },
      {
        field: "destino",
        headerName: "Bodega Destino LI - LOF1",
        width: 150,
        editable: true,
      },
      {
        field: "obs",
        headerName: "Observación",
        width: 100,
        editable: true,
      },
      {
        field: "driverName",
        headerName: "Conductor",
        width: 100,
        editable: true,
        hide: true,
      },
      {
        field: "actions",
        headerName: "Acciones",
        width: 100,
        renderCell: renderActionButtons,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < ordersView.length; i++) {
      rows.push({
        index: i + 1,
        dateDevolucion: format(Date.now(), "dd-MM-yy"), // Formato de fecha
        id: ordersView[i].soc || ordersView[i].id,
        f12: ordersView[i].do || ordersView[i].f12,
        lpn: ordersView[i].lpn || ordersView[i].lpn,
        cantidad: ordersView[i].cantidad || 1,
        motivo: ordersView[i].motivo || "",
        Tienda: ordersView[i].Tienda || "",
        patente: ordersView[i].patente || "",
        destino: ordersView[i].destino || "",
        obs: ordersView[i].obs || "",
        driverName: ordersView[i].driverName || "",
      });
    }

    setRows(rows);
  };

  // console.log(rows);

  // console.log(dispachtHistory);

  const handleOpenEscannerDialog = () => {
    setEditEscannerDialogOpen(true);
  };

  const handleEscannerEditDialog = () => {
    setEditEscannerDialogOpen(false);
  };

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setBulkEditData({
      dateDevolucion: "",
      Tienda: "",
      patente: "",
      destino: "",
      obs: "",
    });
  };

  const handleBulkEditChange = (field, value) => {
    if (field === "dateDevolucion") {
      setBulkEditData((prev) => ({
        ...prev,
        [field]: value, // Mantén el valor como Date
      }));
    } else {
      setBulkEditData((prev) => ({ ...prev, [field]: value }));
    }
  };

  const applyBulkEdit = () => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        selectedIDs.includes(row.id)
          ? {
              ...row,
              ...bulkEditData,
              dateDevolucion: bulkEditData.dateDevolucion
                ? format(new Date(bulkEditData.dateDevolucion), "dd-MM-yy") // Formatear la fecha antes de guardar
                : "", // Manejar valores nulos o vacíos
            }
          : row
      )
    );
    handleCloseEditDialog();
  };

  const handleDeleted = async (id) => {
    const ordersData = ordersView.filter(
      (row) =>
        row.id !== id && row.soc !== id && row.lpn !== id && row.do !== id
    );
    setOrdersView(ordersData);
    console.log("Órdenes restantes después de eliminar:", ordersData);
  };

  const handlePrint = (order) => {
    let dataPrint = [];
    dataPrint.push(order.id);
    printSelect(dataPrint);
  };

  const printSelect = async (selectedData) => {
    try {
      console.log("Datos seleccionados:", selectedData);

      // Filtrar las filas seleccionadas
      const ordersData = await Promise.all(
        rows
          .filter((row) => selectedData.some((order) => row.id === order)) // Validar row.id === order.id
          .map(async (order) => {
            return {
              order,
            };
          })
      );

      if (ordersData.length === 0) {
        console.warn("No hay datos que coincidan con las selecciones.");
        return;
      }

      // Generar el PDF usando la lista filtrada
      const blob = await pdf(
        <PrintDevolutions ordersData={ordersData} />
      ).toBlob();

      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
      console.log("PDF generado con éxito.");
    } catch (error) {
      console.error("Error al generar el PDF:", error);
    }
  };

  const printManifestDevolutions = () => {
    console.log("Imprimir Todo el formulario excell");

    // Crear un mapa de los nombres de las columnas
    const columnHeaders = columns
      .filter((col) => col.field !== "actions") // Excluir las columnas que no son relevantes para la exportación
      .reduce((headers, col) => {
        headers[col.field] = col.headerName; // Mapa de `field` a `headerName`
        return headers;
      }, {});

    // Crear las filas con los encabezados personalizados
    const formattedRows = rows.map((row) => {
      const formattedRow = {};
      for (const key in row) {
        if (columnHeaders[key]) {
          formattedRow[columnHeaders[key]] = row[key]; // Asignar el valor con el encabezado personalizado
        }
      }
      return formattedRow;
    });

    // Crear la hoja y el archivo de Excel
    const worksheet = XLSX.utils.json_to_sheet(formattedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Manifiesto");
    XLSX.writeFile(workbook, "Manifiesto_de_devoluciones.xlsx");

    console.log("Archivo Excel generado con éxito.");
  };

  const closedDevolutions = async () => {
    try {
      if (!manifestData?.id) {
        console.error("No hay un ID de manifiesto válido para cerrar.");
        return;
      }

      // Referencia al documento del manifiesto
      const manifestDocRef = doc(
        db,
        "xpress_manifestDevolutions",
        manifestData.id
      );

      // Actualizar el campo isActive a false
      await setDoc(
        manifestDocRef,
        { isActive: false },
        { merge: true } // Conservar otros campos existentes
      );

      console.log(
        `Manifiesto con ID ${manifestData.id} cerrado correctamente.`
      );
    } catch (error) {
      console.error("Error al cerrar el manifiesto:", error);
    }
  };

  const saveViewDevolutions = async () => {
    try {
      // Crear el objeto del manifiesto
      const manifestDataLocal = {
        id: manifestData?.id || null, // Verifica si ya existe un ID
        companyId: "falabella",
        createBy: props.firebaseUser?.uid || "verificUser",
        date: Timestamp.now(), // Fecha actual
        isActive: true,
        isDeleted: false,
        title: title,
        orders: rows.map((row) => ({
          Tienda: row.Tienda,
          cantidad: row.cantidad,
          dateDevolucion: row.dateDevolucion,
          destino: row.destino,
          f12: row.f12,
          id: row.id,
          index: row.index,
          lpn: row.lpn,
          motivo: row.motivo,
          obs: row.obs,
          patente: row.patente,
        })),
      };

      // Guardar o actualizar el manifiesto en Firebase
      const manifestId = await saveManifestDevolutions(manifestDataLocal);

      console.log(`Manifiesto guardado con ID: ${manifestId}`);
    } catch (error) {
      console.error("Error al guardar el manifiesto:", error);
    }
  };

  const saveManifestDevolutions = async (manifestData) => {
    try {
      const manifestCollectionRef = collection(
        db,
        "xpress_manifestDevolutions"
      );

      if (manifestData.id) {
        // Si el manifiesto ya tiene un ID, se actualiza
        const docRef = doc(manifestCollectionRef, manifestData.id);
        await setDoc(docRef, manifestData, { merge: true }); // Actualiza con merge para conservar campos existentes
        console.log("Manifiesto actualizado correctamente:", manifestData);
        return manifestData.id;
      } else {
        // Si no tiene ID, se crea un nuevo documento
        const docRef = await addDoc(manifestCollectionRef, manifestData);
        // Agrega el ID generado al documento
        await setDoc(doc(db, "xpress_manifestDevolutions", docRef.id), {
          ...manifestData,
          id: docRef.id,
        });
        console.log("Manifiesto creado correctamente:", manifestData);
        return docRef.id;
      }
    } catch (error) {
      console.error("Error al guardar o actualizar el manifiesto:", error);
      throw error;
    }
  };

  const handleOpenDialogDocumentsDevolutions = () =>
    setDialogOpenDocumentsDevolutions(true);
  const handleCloseDialogDocumentsDevolutions = () =>
    setDialogOpenDocumentsDevolutions(false);

  return (
    <Grid sx={{ width: "100%", flex: "row" }}>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#f2f2f2",
        }}
      >
        <Toolbar sx={{ width: "100%" }}>
          <Box display="flex" alignItems="center">
            <Button
              onClick={() => handleSeach(dataSearchOrder)}
              startIcon={<SearchIcon />}
              sx={{
                width: "250px",
                padding: "10px",
                background: "#591e8f",
                borderRadius: "4px",
                color: "#f8f8f8",
                fontSize: "12px",
                fontWeight: "300",
                margin: "1px",
                lineHeight: 1,
                "&:hover": {
                  backgroundColor: "#4a1468",
                },
              }}
            >
              Buscar y Agregar
            </Button>

            <TextField
              fullWidth
              variant="outlined"
              placeholder="ingresa el código del envío..."
              value={dataSearchOrder}
              onChange={(e) => setDataSearch(e.target.value)}
              sx={{
                backgroundColor: "transparent",
                borderRadius: "8px",
                color: "#000",
                fontSize: "inherit",
                paddingInlineEnd: "44px",
                textAlign: "left",
                "& .MuiOutlinedInput-input": {
                  height: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#591e8f",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#591e8f",
                  },
                },
              }}
            />
          </Box>
          <Typography sx={{ color: "#030303", width: "100px" }}>
            Nombre :{" "}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Nombre del archivo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{
              backgroundColor: "transparent",
              borderRadius: "8px",
              color: "#000",
              fontSize: "inherit",
              paddingInlineEnd: "44px",
              textAlign: "left",
              "& .MuiOutlinedInput-input": {
                height: "100%",
              },
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#591e8f",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#591e8f",
                },
              },
            }}
          />
          <Button
            sx={{
              width: "150px",
              height: "40px",
              padding: "10px",
              //background: "#67a900",
              borderRadius: "4px",
              //color: "#030303",
              fontSize: "12px",
              fontWeight: "500",
              margin: "1px",
              lineHeight: 1,
              // "&:hover": {
              //   backgroundColor: "#74b313de",
              // },
            }}
            //  onClick={viewDevolutions}
            onClick={handleOpenDialogDocumentsDevolutions}
          >
            Ver documentos devoluciones
          </Button>
          <DocumentsDialog
            open={dialogOpenDocumentsDevolutions}
            onClose={handleCloseDialogDocumentsDevolutions}
            setManifestData={setManifestData}
          />
        </Toolbar>
      </AppBar>
      <Grid sx={{ width: "100%", backgroundColor: "#f8f8f8" }}>
        <Box
          sx={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          <Box sx={{ display: "flex-grid", backgroundColor: "#F8F8F8" }}>
            <Button
              sx={{
                width: "150px",
                height: "40px",
                padding: "10px",
                background: "#591e8f",
                borderRadius: "4px",
                color: "#f8f8f8",
                fontSize: "12px",
                fontWeight: "300",
                margin: "1px",
                lineHeight: 1,
                "&:hover": {
                  backgroundColor: "#4a1468",
                },
              }}
              onClick={handleOpenEscannerDialog}
            >
              Agregar con escaner
            </Button>
            <Button
              sx={{
                width: "150px",
                height: "40px",
                padding: "10px",
                background: "#591e8f",
                borderRadius: "4px",
                color: "#f8f8f8",
                fontSize: "12px",
                fontWeight: "300",
                margin: "1px",
                lineHeight: 1,
                "&:hover": {
                  backgroundColor: "#4a1468",
                },
              }}
              onClick={printManifestDevolutions}
            >
              Imprimir formualario de devoluciones
            </Button>
            <>
              {selectedIDs.length ? (
                <>
                  <Button
                    sx={{
                      width: "150px",
                      height: "40px",
                      padding: "10px",
                      background: "#591e8f",
                      borderRadius: "4px",
                      color: "#f8f8f8",
                      fontSize: "12px",
                      fontWeight: "300",
                      margin: "1px",
                      lineHeight: 1,
                      "&:hover": {
                        backgroundColor: "#4a1468",
                      },
                    }}
                    onClick={() => printSelect(selectedIDs)}
                    disabled={selectedIDs.length === 0}
                  >
                    imprimir recibos de devolución
                  </Button>
                  <Button
                    sx={{
                      width: "150px",
                      height: "40px",
                      padding: "10px",
                      background: "#591e8f",
                      borderRadius: "4px",
                      color: "#f8f8f8",
                      fontSize: "12px",
                      fontWeight: "300",
                      margin: "1px",
                      lineHeight: 1,
                      "&:hover": {
                        backgroundColor: "#4a1468",
                      },
                    }}
                    onClick={handleOpenEditDialog}
                    disabled={selectedIDs.length === 0}
                  >
                    Editar masivamente
                  </Button>
                  <Button
                    sx={{
                      width: "150px",
                      height: "40px",
                      padding: "10px",
                      background: "#67a900",
                      borderRadius: "4px",
                      color: "#f8f8f8",
                      fontSize: "12px",
                      fontWeight: "500",
                      margin: "1px",
                      lineHeight: 1,
                      "&:hover": {
                        backgroundColor: "#74b313de",
                      },
                    }}
                    onClick={closedDevolutions}
                    disabled={selectedIDs.length === 0}
                  >
                    Cerrar viajes
                  </Button>
                </>
              ) : null}
            </>

            <Button
              sx={{
                width: "150px",
                height: "40px",
                padding: "10px",
                //background: "#67a900",
                borderRadius: "4px",
                //color: "#030303",
                fontSize: "12px",
                fontWeight: "500",
                margin: "1px",
                lineHeight: 1,
                // "&:hover": {
                //   backgroundColor: "#74b313de",
                // },
              }}
              onClick={saveViewDevolutions}
            >
              Guardar cambios
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          Manifiesto de devoluciones :
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={1000}
            rowsPerPage={[50, 100, 1000]}
            pagination
            localeText={customLocaleText} // Aquí se aplica la localización
            slots={{
              toolbar: GridToolbar, // Incluye la barra de herramientas para opciones adicionales
            }}
            groupingModel={["driverName"]}
            checkboxSelection
            onRowSelectionModelChange={(items) => HandleSeleccions(items)}
            rowSelectionModel={selectedIDs}
          ></DataGrid>
        </Box>

        <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DialogTitle>Editar Masivamente</DialogTitle>
            <DialogContent>
              <DatePicker
                label="Fecha Devolución"
                value={bulkEditData.dateDevolucion || null}
                onChange={
                  (newValue) => handleBulkEditChange("dateDevolucion", newValue) // format(newValue, "dd-MM-yy")
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth margin="normal" />
                )}
              />
              <TextField
                fullWidth
                label="Tienda"
                value={bulkEditData.Tienda}
                onChange={(e) => handleBulkEditChange("Tienda", e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Patente"
                value={bulkEditData.patente}
                onChange={(e) =>
                  handleBulkEditChange("patente", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Bodega Destino"
                value={bulkEditData.destino}
                onChange={(e) =>
                  handleBulkEditChange("destino", e.target.value)
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Observación"
                value={bulkEditData.obs}
                onChange={(e) => handleBulkEditChange("obs", e.target.value)}
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog}>Cancelar</Button>
              <Button
                onClick={applyBulkEdit}
                sx={{ background: "#591e8f", color: "#fff" }}
              >
                Aplicar
              </Button>
            </DialogActions>
          </LocalizationProvider>
        </Dialog>

        <Dialog
          open={editEscannerDialogOpen}
          onClose={handleEscannerEditDialog}
        >
          <DialogContent>
            <Box
              sx={{
                position: "relative",
                width: "300px", // Tamaño del cuadrado
                height: "300px",
                border: "2px solid #591e8f",
                overflow: "hidden",
              }}
            >
              {/* Barra de escaneo animada */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "10px",
                  backgroundColor: "#591e8f",
                  animation: "scannerAnimation 2s linear infinite",
                }}
              />
            </Box>
            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <p>Escaneando...</p>
              <p>{scannerInput}</p>
            </Box>
            <style>
              {`
        @keyframes scannerAnimation {
          from {
            top: 0;
          }
          to {
            top: 100%;
          }
        }
      `}
            </style>
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default ScannerDevoluciones;
